import { inventoryTypeService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { InventoryTypeResource } from '@/resource/model';

import { messageError, translation } from '@/utils';

import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
// import SelectCurrency from './select-currency/select-currency.vue';

@Component({
  name: 'AddInventoryType',
  components: {}
})
export default class AddInventoryType extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: InventoryTypeResource | null;
  public dialogVisible: boolean = false;
  public valueType: string = '';

  public form: InventoryTypeResource = {
    id: null,
    code: '',
    name: ''
  };

  public resourceFormRules = {
    code: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          inventoryTypeService
            .checkCode(value, this.form?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.codeRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
      // {
      //   validator: (rule: any, value: string, callback: Function): void => {
      //     inventoryTypeService
      //       .checkName(value, this.position?.id)
      //       .then((isRepeat: boolean) => {
      //         if (isRepeat) {
      //           callback(translation('requestError.regionNameRepeat'));
      //           return;
      //         }
      //         callback();
      //       })
      //       .catch(error => {
      //         callback(error);
      //       });
      //   },
      //   trigger: 'blur'
      // }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'inventoryType.title';
      this.$nextTick(() => {
        Object.assign(this.form, this.position);
      });
      return;
    }
    this.operationType = 'add';
    this.title = 'inventoryType.title';
    Object.assign(this.form, {
      id: null,
      code: '',
      name: ''
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await inventoryTypeService.post({ ...this.form } as any);
          Message.success(translation('operationRes.operationSuccess'));
          this.$emit('operate-success');
          this.closeDialog();
          return;
        }
        await inventoryTypeService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
