import { inventoryTypeService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
import { InventoryTypeDetails } from '@/resource/model';
import { messageError, translation } from '@/utils';

import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'DetailsInventoryType',
  components: {}
})
export default class DetailsInventoryType extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Number,
    default: null
  })
  public id!: number | null;

  public disabled: boolean = true;
  public dialogVisible: boolean = false;

  public info: InventoryTypeDetails | null = null;
  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.info!.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.info!.enableStatus) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  public dialogOpen(): void {
    this.title = 'inventoryType.title';
    this.getDetails();
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public getDetails(): void {
    if (!this.id) {
      return;
    }
    inventoryTypeService
      .getById(this.id!)
      .then((res: InventoryTypeDetails) => {
        this.info = res;
      })
      .catch(err => {
        messageError(err);
      });
  }
}
